<template>
  <div class="box">
    <div v-show="loading">
      <div
        class="turntableBox"
        v-bind:style="{
          backgroundImage: 'url(' + imgUrl + imgList.backgroundImg + ')',
        }"
      >
        <!-- 红包 -->
        <div class="envelopes">
          <img :src="imgUrl + imgList.prizePlateConfig.outerImg" alt="" />
          <div class="openBut" @click="startrotate()">
            <img :src="imgUrl + imgList.pointImg" alt="" />
          </div>
        </div>
        <!-- 问题咨询 -->
        <div class="consulting">
          <div class="question" @click="questionHref()">
            <img src="../../assets/wtzx.png" alt="" />
          </div>
          <div class="question" @click="agreement()">
            <img src="../../assets/yssm.png" alt="" />
          </div>
        </div>

        <!-- 中奖名单 -->
        <div class="winning">
          <img :src="imgUrl + imgList.prizeFormImg" alt="" />
          <div class="winningBox">
            <vue-seamless-scroll
              class="seamless-warp"
              :data="winningList"
              :class-option="classOption"
            >
              <ul class="winningList">
                <li v-for="(item, index) in winningList" :key="index">
                  <span>{{ item.nickName }}</span>
                  <span>{{ item.prizeName }}</span>
                  <span>{{ item.createTime }}</span>
                </li>
              </ul>
            </vue-seamless-scroll>
          </div>
        </div>
        <!-- 活动规则 -->
        <div class="rule">
          <!-- <img :src="imgUrl + imgList.rulesImg" alt="" /> -->
          <div v-html="ruleText"></div>
        </div>
      </div>
      <assistant @click="showFather"></assistant>
      <winningRecord
        :recordShow="recordShow"
        :recordList="recordList"
        @click="showFather"
      ></winningRecord>
      <div class="bottomLogo">
        <img src="../../assets/redEnvelopes/bottom.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { getImgUrl } from "../../api/index.js";
import { getDrawRecord } from "../../api/index.js";
import { wxshareNO } from "../../utils/date.js";
import { drawRecord } from "../../api/index.js";
import { journal, getWinningRecord } from "../../api/index.js";
import assistant from "../../components/assistant/index";
import winningRecord from "../../components/winningRecord/index";
import { areaList } from "@vant/area-data";
export default {
  name: "authorize",
  data() {
    return {
      recordShow: false, //展示小助手
      openState: true,
      isBlacklist: false,
      loading: true,
      winningList: [],
      prizeList: [],
      recordList: {
        prizeLogLists: [],
        wealLogLists: [],
        awardsLogLists: [],
      },
      time: 3000, // 旋转时间
      rotNum: 3, // 旋转圈数基数
      imgUrl: process.env.VUE_APP_BASE_imgUrl,
      imgList: {
        pointImg: "",
        prizeFormImg: "",
        rulesImg: "",
        backgroundImg: "",
        prizePlateConfig: {
          outerImg: "",
          innnerImg: "",
        },
      },
      base: "",
      tipsShow: false,
      shopInputShow: false,
      isBinding: false,
      showArea: false,
      shop: {
        shopName: "",
        shopPhone: "",
        shopDetailed: "",
        shopAddress: "",
        province: "",
        city: "",
        area: "",
      },
      areaList: areaList,
      ruleText: "", //活动规则文案
    };
  },
  components: {
    assistant: assistant,
    winningRecord: winningRecord,
  },
  created() {
    this.$nextTick(() => {
      this.getWinning();
    });
  },
  computed: {
    classOption() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: this.winningList.length, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: false, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
        base: "22.5",
        rotate: 0,
        openTouch: false,
      };
    },
  },
  methods: {
    autoRotate(arr) {
      if (arr.length) {
        let len = arr.length;
        let base = 360 / len;
        this.base = 360 / len;
        arr.forEach((item, index) => {
          // 指的是某个奖品区域的中间 : base/2
          item.angle = 360 - (base / 2 + index * base);
          this.$set(arr, index, item);
        });
      }
      return arr;
    },
    //开始旋转 angle角度  complete回调成功函数
    startrotate() {
      console.log(this.openState);
      var that = this;

      if (this.openState) {
        this.openState = false;
        drawRecord().then(
          (res) => {
            this.openState = true;
            if (res.data.isTrue == 1 || res.data.isTrue == 0) {
              window.location.href = res.data.skipUrl;
            } else if (res.data.isTrue == 3) {
              this.$router.push({
                path: "/redEnvelopes",
                query: {
                  prizeId: res.data.prizeId,
                  drawLogId: res.data.drawLogId,
                },
              });
            }
          },
          function () {
            that.openState = true;
          }
        );
      }
      // 点击转盘埋点
      var maidian = {
        userVisit: "3",
      };
      journal(maidian);
    },
    showFather() {
      this.recordShow = !this.recordShow;
      if (this.recordShow) {
        document.body.style.overflow = "hidden";
        getWinningRecord().then((res) => {
          this.recordList = res.data;
        });
        var maidian = {
          userVisit: "8",
        };
        journal(maidian);
      } else {
        document.body.style.overflow = ""; //出现滚动条
      }
    },
    getImg() {
      getImgUrl({}).then((res) => {
        this.prizeList = res.data.prizePlateConfig.prizePlatConfig;
        this.imgList.outerImg = res.data.prizePlateConfig.outerImg;
        this.imgList = res.data;
        // this.autoRotate(res.data.prizePlateConfig.prizePlatConfig);
      });
    },
    getWinning() {
      getDrawRecord().then((res) => {
        this.winningList = res.data;
      });
    },
    questionHref() {
      var openId = sessionStorage.getItem("openId");
      var projectCode = sessionStorage.getItem("projectCode");
      window.location.href =
        process.env.VUE_APP_Question_API +
        "/questionPhase/questionPhase.html?channelId=" +
        projectCode +
        "&titleName=400032&openId=" +
        openId;
    },
    agreement() {
      window.location.href =
        process.env.VUE_APP_Question_API + "/questionPhase/adult.html";
    },
    goBack() {
      this.rotate = 0;
      // this.oTurntable.style.webkitTransition =
      //   "transform 0s cubic-bezier(0.01,0.5,0.01,1)";
      // this.oTurntable.style.webkitTransform = "rotate(0deg)";
      console.log("返回");
    },
    closeShop() {
      this.shopInputShow = false;
    },
  },
  mounted: function () {
    console.log("更新啦1");
    wxshareNO();
    var that = this;
    history.pushState(null, null, document.URL);
    window.addEventListener(
      "popstate",
      function (e) {
        history.pushState(null, null, document.URL);
      },
      false
    );
    var browserRule = /^.*((iPhone)|(iPad)|(Safari))+.*$/;
    if (browserRule.test(navigator.userAgent)) {
      window.onpageshow = function (event) {
        if (event.persisted) {
          that.goBack();
          wxshareNO();
        }
      };
    }
    this.$nextRequest(this.getImg);
    var projectCode = sessionStorage.getItem("projectCode");
    if (projectCode == 32010680) {
      this.ruleText = window.rule.text14;
    } else {
      this.ruleText = window.rule.text7;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.box {
  position: relative;
}
.turntableBox {
  width: 100%;
  /* background: url(../../assets/backgroundurl.png) no-repeat; */
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: top;
  padding-top: 420px;
  padding-bottom: 1px;
}

.turntableBox img {
  display: block;
  width: 100%;
}

.outer {
  position: relative;
  width: 658px;
  margin: 0 auto;
  overflow: hidden;
}

.inner {
  width: 598px;
  position: absolute;
  top: 30px;
  left: 30px;
}

.pointer {
  width: 188px;
  position: absolute;
  left: 235px;
  top: 190px;
  z-index: 2;
}

.question {
  width: 140px;
  margin: 40px auto 0;
}

.winning {
  position: relative;
  width: 540px;
  margin: 0 auto;
}

.winningBox {
  position: absolute;
  width: 420px;
  height: 160px;
  left: 60px;
  top: 150px;
  overflow: hidden;
}

.winningList li {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  line-height: 44px;
}

.winningList li span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.winningList li span:nth-child(2) {
  width: 180px;
}
.winningList li span:last-child {
  text-align: right;
}

.winningList li span:last-child {
  width: 120px;
  flex-shrink: 0;
}

.winningList li span:first-child {
  width: 90px;
  flex-shrink: 0;
}

.rule {
  width: 590px;
  margin: 40px auto 192px;
  border-radius: 10px;
  background: rgba(169, 99, 0, 0.3);
  padding: 30px;
  overflow: auto;
  max-height: 648px;
}

.support {
  width: 100%;
  background: #dcdcdc;
}

.prize {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.prize li {
  position: absolute;
  box-sizing: border-box;
  color: #7e250d;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  line-height: 20px;
  transform-origin: 50% 50%;
}

.prize li img {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 148px;
}
.shopInput {
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}
.shopContent {
  width: 542px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.shopContent img {
  width: 100%;
}
.shopList {
  position: absolute;
  top: 180px;
  left: 50%;
  transform: translatex(-50%);
  font-size: 26px;
  color: #fff;
}
.shopList li {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.shopList li span {
  width: 110px;
  display: block;
  flex-shrink: 0;
}
.shopList li input {
  width: 285px;
  height: 44px;
  border: 2px solid #ffffff;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0);
  margin-left: 30px;
}
.shopList li input::placeholder,
.shopList li textarea::placeholder {
  color: #c3a3a2;
}
.shopList li textarea {
  width: 285px;
  height: 86px;
  border: 2px solid #ffffff;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0);
  margin-left: 30px;
  resize: none;
}
.submitInformation {
  width: 416px;
  height: 64px;
  background: #ffc154;
  border-radius: 5px;
  font-size: 22px;
  color: #000000;
  border: none;
  margin: 0 auto;
}
.shopClose {
  width: 70px;
  height: 70px;
  margin: 20px auto;
}
.envelopes {
  position: relative;
  width: 100%;
}
.openBut {
  position: absolute;
  top: 400px;
  width: 138px;
  left: 306px;
  animation: move 0.8s 0s infinite;
  -webkit-animation: move 0.8s 0s infinite;
  transform-origin: bottom;
  -webkit-transform-origin: bottom;
}
.consulting {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
@keyframes move {
  0% {
    transform: scale(1.1);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}
.shopClose img,
.blacklist,
.blacklist img,
.envelopes img,
.openBut img,
.bottomLogo img {
  display: block;
  width: 100%;
}
.bottomLogo {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 447px;
}
</style>
